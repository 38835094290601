.embla {
    position: relative;
    padding: 0px;
    width: 100%;
}

.embla__viewport {
    /* overflow: hidden; */
    width: 100%;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.embla__slide_gallery {
    position: relative;
    padding-left: 10px;
    /* flex: 0 0 25%;
    margin-right: 0px; */
    min-width: 25%;
}
/* 
  .x4.embla__slide {
    position: relative; 
    flex: 0 0 25%; 
    margin-right: 0px;
   
  }

  .x5.embla__slide {
    position: relative; 
    flex: 0 0 20%; 
    margin-right: 0px;
   
  }

  .x7.embla__slide {
    position: relative; 
    flex: 0 0 14.2%; 
    margin-right: 0px;
   
  } */

.embla__slide__inner {
    position: relative;
    overflow: hidden;
    height: 400px;
}

.embla__slide__img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
}

.embla__button {
    /* outline: 0; */
    cursor: pointer;
    /* background-color: transparent; */
    touch-action: manipulation;
    /* position: absolute; */
    /* z-index: 1;
    top: 50%; */
    /* transform: translateY(-50%); */
    /* border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0; */
}

.embla__button:disabled {
    cursor: default;
    opacity: 0.3;
}

.embla__button__svg {
    width: 100%;
    height: 100%;
}

.embla__button--prev {
    /* left: 27px; */
}

.embla__button--next {
    /* right: 27px; */
}
