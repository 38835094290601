.has-booking {
    padding: 4px;
    color: rgb(0, 199, 106);
    border: 2px solid rgb(0, 255, 136);
    border-radius: 99999px;
    -webkit-border-radius: 99999px;
    -moz-border-radius: 99999px;
    -ms-border-radius: 99999px;
    -o-border-radius: 99999px;
}

.date_used_already {
    color: orange !important;
}
