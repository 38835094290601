.DateRangePickerInput__withBorder {
    border-radius: 2rem;
    display: flex;
    align-items: center;
}

.DateInput.DateInput_1 {
    border-radius: 2rem;
}

.CalendarDay__selected_span {
    background: #00cc97;
    border: none;
}
.DateInput {
    width: auto;
    height: 3rem;
}
.DateInput_input {
    border-radius: 2rem;
    font-size: 0.85rem;
    font-weight: 600;
    text-align: center;
}
.DateInput_input__focused {
    border: 2px solid #31cf95;
}

.CalendarDay__selected {
    background: #31cf95;
}
.DateRangePickerInput_arrow {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    border: 1px solid #31cf95;
}
